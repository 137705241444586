//Extra small mobile
@mixin extrasm {
  @media (max-width: 575.98px) {
    @content;
  }
}

//Mobile
@mixin mobile {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}

// Tablet + Mobile
@mixin menma {
  @media (max-width: 1023px) {
    @content;
  }
}

// Small and medium
@mixin smmd {
  @media (max-width: 991.98px) {
    @content;
  }
}
