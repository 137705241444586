@use '@app/scss/breakpoint' as *;

.user-auth {
  .login {
    width: 100%;
    height: 70%;
    & > h3 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 2.7rem;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 20px 0 25px 0;
      opacity: 0.9;
    }
    &__subtitle {
      width: 100%;
      text-align: left;
      & > span {
        font-weight: 500;
        font-size: 1.3rem;
        margin: 15px 0;
        color: rgba(0, 0, 0, 0.8);
      }

      & strong {
        cursor: pointer;
        color: #ff514e;
      }
    }
    &__form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px;
      padding: 30px;
      min-height: 90%;
      margin-bottom: 30px;
      &-select {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        & .ant-select-selector {
          box-shadow: none !important;
          border: none !important;
          outline: none !important;
          // padding: 0 11px 0 0 !important;
          margin-left: -11px !important;
        }
      }
      &--field {
        width: 100%;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.84);
        & > label {
          font-size: 1.3rem;
        }
      }
      &--wrapper {
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin-top: 7px;
        background-color: #f5f5f5;
        & > svg {
          width: 18px;
          height: 18px;
          margin: 0 8px;
          color: rgba(0, 0, 0, 0.4);
        }
        & > span {
          & > svg {
            width: 18px;
            height: 18px;
            margin: 0 8px;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        & > input {
          border: none;
          outline: none;
          flex: 1 1;
          padding: 12px 0;
          background-color: transparent;
        }
      }
      &--err {
        display: inline-block;
        font-size: 1.3rem;
        margin-top: 0px;
        color: red;
      }
      &-btn {
        width: 100%;
        color: #fff;
        background-color: rgb(61, 106, 158);
        border-radius: 8px;
        height: 40px;
        border: none;
        outline: none;
        margin: 10px 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: row-reverse;
        & > svg {
          font-size: 2rem;

          margin-top: 3px;
        }
        & > span {
          display: block;
          font-size: 1.6rem;
          margin-right: 10px;
        }
      }
      &-forgot {
        transition: color 0.2s ease-in-out;
        text-align: left;
        width: 100%;
        &:hover {
          color: #ff514e;
          cursor: pointer;
        }
      }
    }
  }

  .login-error {
    display: flex;
    align-items: center;
    &__title {
      font-size: 2.5rem;
      width: 100%;
      text-align: center;
    }
    &__info {
      height: 230px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__contact {
      font-size: 1.7rem;
      & > span {
        font-weight: 500;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    &__contact-bt {
      font-size: 1.7rem;
      & > span {
        font-weight: 500;
        display: block;
      }
    }
    &__banner {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .left-header {
    float: left;
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;

    h1 {
      line-height: 0;
    }
  }

  .right-header {
    float: right;
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;

    .register {
      @include mobile() {
        display: none;
      }

      .header-button {
        background-color: #fcfcfc;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 11px;
        width: 124px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
      }
    }
    .language-dropdown {
      @include mobile() {
        display: none;
      }
    }
  }

  .menu-mobile {
    display: none;

    @include mobile() {
      display: block;
      position: absolute;
      left: 15px;
    }
  }

  .ant-drawer-close {
    .menu-icon {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  }

  .ant-drawer-body {
    background: #4b6385;

    .header-button {
      background-color: #fcfcfc;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 11px;
      width: 124px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
    }
  }

  .footer-banner {
    background: #4b6385 !important;
  }
}
