@use '@app/scss/breakpoint' as *;

.front-footer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 20px;

  .footer-banner {
    height: 212px;
    background: #092a5b;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2,
    p {
      margin: 0;

      @include mobile() {
        max-width: 90%;
        text-align: center;
      }
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    background-color: white;

    p {
      height: 15px;
      margin: 0;
      font-size: 12px;
    }
  }
}
