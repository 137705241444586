.language-dropdown {
  position: relative;
  margin: 10px 10px;
  display: flex;
  z-index: 1;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &-icon {
    z-index: 10;
    position: absolute;
    top: 30%;
  }

  &-text {
    margin-left: 5px;
    display: flex;
    color: white;
    z-index: 11;
    justify-content: right;
    padding: 0 15px;
    align-items: center;

    p {
      margin: 0;
      height: fit-content;
    }
  }

  .arrow-down-white {
    margin: 5px;
    z-index: 1;
    position: absolute;
    top: 30%;
    left: 100%;
  }
}
