.global-spinner {
  height: 100vh;
  width: 100wh;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 1rem;
    color: var(--active-blue-color);
  }

  & .ant-spin-blur::after {
    opacity: 0 !important;
  }

  .content {
    padding: 50px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
}
